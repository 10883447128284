angular.module("adminApp", ["common"])
    .config(translateConfig)
    .run(['$rootScope', '$translate', 'PageService', 'instructionsAdmin',
        function ($rootScope, $translate, PageService, instructionsAdmin) {

            (function setAdminInstructionPages() {
                PageService.addPages(instructionsAdmin);
            })();

            $rootScope._ = _;
            $translate.refresh();
        }])
    .run(customTranslationStrategy);
